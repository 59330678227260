<template>
  <ManeuverListComponent />
</template>
<script>
import ManeuverListComponent from "@/components/ManeuverListComponent";

export default {
  name: "maneuver-list",
  components: {
    ManeuverListComponent,
  },

  mounted() {},
  methods: {},
  created() {},
};
</script>
