<template>
  <NotAllowedListComponent />
</template>
<script>
import NotAllowedListComponent from "@/components/NotAllowedListComponent";

export default {
  name: "notallowed-list",
  components: {
    NotAllowedListComponent,
  },

  mounted() {},
  methods: {},
  created() {},
};
</script>
