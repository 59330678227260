<template>
  <div class="loginContainer">
    <LoginComponent />
  </div>
</template>

<script>
import LoginComponent from "@/components/LoginComponent";
export default {
  name: "login",
  components: {
    LoginComponent,
  },
  mounted() {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.loginContainer {
  width: 100%;
  height: 100%;
  background-image: url("../assets/" + $BACKGROUND_PATH + "");
  background-size: cover;
  background-position: center;
}
</style>
