import { TableConfig } from "./TableConfig";
export class TableResponse {
  config: TableConfig;
  rows: Object[];
  totalRecords: number;
  constructor(config: TableConfig, rows: Object[], totalRecords: number) {
    this.config = config;
    this.rows = rows;
    this.totalRecords = totalRecords;
  }
}
