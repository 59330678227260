export class amuraEvent {
  public id: number;
  public version: string;
  public timestamp: Date;
  public senttimestamp: Date;
  public sentby: Date;
  public eventtype: string;
  public event: string;
  public uuid: string;
  public additionaldata: Object;
  public vendorextension: Object;

  constructor(
    id: number,
    version: string,
    timestamp: Date,
    senttimestamp: Date,
    sentby: Date,
    eventtype: string,
    event: string,
    uuid: string,
    additionaldata: Object,
    vendorextension: Object
  ) {
    this.id = id;
    this.version = version;
    this.timestamp = timestamp;
    this.senttimestamp = senttimestamp;
    this.sentby = sentby;
    this.eventtype = eventtype;
    this.event = event;
    this.uuid = uuid;
    this.additionaldata = additionaldata;
    this.vendorextension = vendorextension;
  }
}
